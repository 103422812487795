import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
import './plugins/element.js'
import '@/styles/common/reset.less'
import '@/styles/common/common.less'
import '@/styles/common/elementReset.less'
import '@/assets/iconfont/iconfont.js'
import '@/assets/iconfont/iconfont.css'

Vue.config.productionTip = false
Vue.use(Meta)

new Vue({
  router,
  store,
  metaInfo() {
    return {
      title: store.state.common.meta.title,
      meta: [
        {
          name: 'keywords',
          comtent: store.state.common.meta.keywords
        },
        {
          name: 'description',
          comtent: store.state.common.meta.description
        }
      ]
    }
  },
  render: (h) => h(App)
}).$mount('#app')
