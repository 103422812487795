export function getLanguage() {
  const arr = window.location.pathname.split('/')
  const LANGUAGES = [
    'en',
    'uk',
    'vi'
  ]
  let lang = ''
  if (arr[1] === 'business' || arr[1] === 'consumer') {
    LANGUAGES.includes(arr[2]) ? lang = `lan/${arr[2]}` : lang = 'lan/en'
    // lang = `lan/${arr[2]}`
  } else {
    lang = `consumer/en`
  }
  return lang
}

export default getLanguage()
