import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common'
import header from './modules/header'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    header
  }
})
