import { getLang, getLanguage, getPublicNav, getBanner, getNavigator } from '@/api/public'
const state = {
  lang: null, // 语言
  LP: {}, // 语言包
  headerInfo: null, // 头部导航
  footerInfo: null, // 底部导航
  banner: null, // banner
  footerBanner: null, // 关于我们底部banner
  navigator: null, // 面包屑
  breadCrumbTitle: '',
  meta: {
    title: '',
    description: '',
    keywords: ''
  }
}

const getters = {
}

const actions = {
  // 获取多语言
  async getLang({ commit }) {
    const data = await getLang()
    commit('SET_LANG', data.data)
  },
  // 获取语言包
  async getLanguage({ commit }) {
    const data = await getLanguage()
    commit('SET_LANGUAGE', data.data)
  },
  // 头部/底部导航
  async getPublcNav({ commit }, params) {
    const data = await getPublicNav({
      type: params
    })
    if (params === 'header') {
      commit('SET_HEADER_INFO', data.data)
    }
    if (params === 'footer') {
      commit('SET_FOOTER_INFO', data.data)
    }
  },
  // banner
  async getBanner({ commit }, params) {
    const data = await getBanner(params)
    commit('SET_BANNER', data.data)
  },
  // 关于我们底部banner
  async getFooterBanner({ commit }, params) {
    const data = await getBanner(params)
    commit('SET_FOOTER_BANNER', data.data)
  },
  // 面包屑
  async getNavigator({ commit }, params) {
    const data = await getNavigator(params)
    commit('SET_NAVIGATOR', data.data)
  }
}

const mutations = {
  SET_LANG: (state, params) => {
    state.lang = params
  },
  SET_LANGUAGE: (state, params) => {
    state.LP = params.languages
  },
  SET_HEADER_INFO: (state, params) => {
    state.headerInfo = params
  },
  SET_FOOTER_INFO: (state, params) => {
    state.footerInfo = params
  },
  SET_BANNER: (state, params) => {
    state.banner = params
  },
  SET_FOOTER_BANNER: (state, params) => {
    state.footerBanner = params
  },
  SET_NAVIGATOR: (state, params) => {
    state.navigator = params
    // state.breadCrumbTitle = JSON.parse(JSON.stringify(params)).splice(params.length - 1)[0].nav_name
  },
  SET_MATE: (state, params) => {
    state.meta.title = params.meta_title
    state.meta.description = params.meta_description
    state.meta.keywords = params.meta_keyword
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
