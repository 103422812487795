import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

/** ******************************* 懒加载路由 *********************************/
const defaultLayout = () =>
  import(/* webpackChunkName: "layout" */ '@/layout/default.vue')
const home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue') // 首页
const products = () => import(/* webpackChunkName: "products" */ '@/views/products/Index.vue') // 产品类别首页
const productCategories = () => import(/* webpackChunkName: "products" */ '@/views/products/productCategories/Index.vue') // 产品类别二级页面
const productSelector = () => import(/* webpackChunkName: "products" */ '@/views/products/productSelector/Index.vue') // 产品选择页
const productCompare = () => import(/* webpackChunkName: "products" */ '@/views/products/productSelector/compare/Index.vue') // 产品对比页
const productDetail = () => import(/* webpackChunkName: "products" */ '@/views/products/productDetail/Index.vue') // 产品详情页
const solutions = () => import(/* webpackChunkName: "solutions" */ '@/views/solutions/Index.vue') // 解决方案列表
const solutionsDatail = () => import(/* webpackChunkName: "solutions" */ '@/views/solutions/Detail.vue') // 解决方案详情二级页面
const support = () => import(/* webpackChunkName: "support" */ '@/views/support/Index.vue') // 服务支持首页
const downloadCenter = () => import(/* webpackChunkName: "support" */ '@/views/support/DownloadCenter.vue') // 服务支持-下载中心
const servicePolicy = () => import(/* webpackChunkName: "support" */ '@/views/support/ServicePolicy.vue') // 服务支持-服务政策
const tools = () => import(/* webpackChunkName: "support" */ '@/views/support/Tools.vue') // 服务支持-工具
const search = () => import(/* webpackChunkName: "search" */ '@/views/search/Index.vue') // 搜索
const contactUs = () => import(/* webpackChunkName: "contactUs" */ '@/views/contactUs/Index.vue') // contact
const newsletter = () => import(/* webpackChunkName: "newsletter" */ '@/views/newsletter/Index.vue') // Newsletter Subscription
const aboutUs = () => import(/* webpackChunkName: "aboutUs" */ '@/views/aboutUs/Index.vue') // 关于我们
const newsEvents = () => import(/* webpackChunkName: "newsEvents" */ '@/views/newsEvents/Index.vue') // News & Events
const newsList = () => import(/* webpackChunkName: "newsEvents" */ '@/views/newsEvents/NewsList.vue') // News列表页
const newsDetail = () => import(/* webpackChunkName: "newsEvents" */ '@/views/newsEvents/NewsDetail.vue') // News详情页
const singlePage = () => import(/* webpackChunkName: "newsEvents" */ '@/views/singlePage/Index.vue') // 单页
const whereToBuy = () => import(/* webpackChunkName: "partner" */ '@/views/partner/WhereToBuy.vue') // Partner- where to buy
const notFound = () => import(/* webpackChunkName: "notFound" */ '@/views/notFound/Index.vue') // 404
const videos = () => import(/* webpackChunkName: "videos" */ '@/views/videos/Index.vue') // 视频列表

/** ********* 静态路由 ***********/
export const staticRoutes = [
  {
    path: '/:site/:lang',
    component: defaultLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: home
      },
      {
        path: 'products',
        name: 'products',
        component: products
      },
      {
        path: 'products/productCategories',
        name: 'productCategories',
        component: productCategories
      },
      {
        path: 'products/productSelector',
        name: 'productSelector',
        component: productSelector
      },
      {
        path: 'products/productSelector/compare',
        name: 'productCompare',
        component: productCompare
      },
      {
        path: 'products/productDetail',
        name: 'productDetail',
        component: productDetail
      },
      {
        path: 'solutions',
        name: 'Solutions',
        component: solutions
      },
      {
        path: 'solutions/detail',
        name: 'SolutionsDatail',
        component: solutionsDatail
      },
      {
        path: 'support',
        name: 'support',
        component: support
      },
      {
        path: 'support/download',
        name: 'downloadCenter',
        component: downloadCenter
      },
      {
        path: 'support/service-policy',
        name: 'servicePolicy',
        component: servicePolicy
      },
      {
        path: 'support/tools',
        name: 'tools',
        component: tools
      },
      {
        path: 'search',
        name: 'search',
        component: search
      },
      {
        path: 'contactUs',
        name: 'contactUs',
        component: contactUs
      },
      {
        path: 'newsletter-subscription',
        name: 'newsletter',
        component: newsletter
      },
      {
        path: 'about-us',
        name: 'aboutUs',
        component: aboutUs
      },
      {
        path: 'newsEvents',
        name: 'newsEvents',
        component: newsEvents
      },
      {
        path: 'newsList',
        name: 'newsList',
        component: newsList
      },
      {
        path: 'newsDetail',
        name: 'newsDetail',
        component: newsDetail
      },
      {
        path: 'singlePage/:id',
        name: 'singlePage',
        component: singlePage
      },
      {
        path: 'where-to-Buy/:id',
        name: 'whereToBuy',
        component: whereToBuy
      },
      {
        path: 'videos',
        name: 'videos',
        component: videos
      },
      {
        path: '404',
        name: 'notFound',
        component: notFound
      },
      {
        path: '*',
        redirect: '404'
      }
    ]
  }
]
/** ********* 动态路由 ***********/
export const asyncRoutes = [
  {
    path: '/',
    component: defaultLayout,
    children: []
  }
]

/** ********* 路由初始化 ***********/
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...staticRoutes, ...asyncRoutes],
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})
/** ********* 点击重复路由报错问题 ***********/
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}
/** ********* 全局路由导航守卫 ***********/
router.beforeEach(async (to, from, next) => {
  // 如果语言包没存储过，则存储
  if (!Object.keys(store.state.common.LP).length) {
    await store.dispatch('common/getLanguage')
    // 绑定语言包至原型，这样不用每个页面再引入了
    Vue.prototype.LP = store.state.common.LP !== undefined ? store.state.common.LP : []
  }
  const site = ['consumer', 'business']
  const sites = to.params.site
  const langs = to.params.lang
  await store.dispatch('common/getLang')
  const langArr = JSON.parse(JSON.stringify(store.state.common.lang))
  const index = langArr.findIndex(item => item.language_code === '')
  langArr[index].language_code = 'en'
  const flag = langArr.some(item => item.language_code === langs)
  // // 路由包含站点 语言
  if (site.includes(sites) && flag) {
    return next()
  } else if (site.includes(sites)) {
    // 路由不含站点语言
    const nameArr = window.location.pathname.split('/')
    if (nameArr[1] === '' || store.state.common.LP === undefined) {
      store.state.common.LP = []
      return next(`/${sites}/en`)
    } else {
      return next(`/${sites}/en/404`)
    }
  } else {
    // 路由不含'consumer', 'business'
    return next(`/consumer/en/Products`)
  }
  // if (site.includes(nameArr[1]) && store.state.common.LP !== undefined) {
  //   return next(`/${nameArr[1]}/${nameArr[2]}`)
  // } else {
  //   if (nameArr[1] === '' || store.state.common.LP === undefined) {
  //     store.state.common.LP = []
  //     return next(`/${sites}/en`)
  //   } else {
  //     return next(`/consumer/en/404`)
  //   }
  // }
})

export default router
