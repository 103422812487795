import 'babel-polyfill'
import apiConfig from '../../api.config'
import axios from 'axios'
import qs from 'qs'

// axios 配置
axios.defaults.timeout = 60000

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.post['accept'] = 'application/json, text/plain, */*'
axios.defaults.baseURL = apiConfig.baseUrl

// POST传参序列化
axios.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 返回状态判断
axios.interceptors.response.use(
  res => {
    return res
  },
  error => {
    return Promise.reject(error)
  }
)

export function fetch(url, params, method = 'get') {
  // data post传参用
  if (method === 'post') {
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: params,
        method
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  return new Promise((resolve, reject) => {
    axios({
      url,
      params,
      method
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
