const state = {
  whiteHeader: false // 是否显示白色导航
}

const getters = {}

const mutations = {
  // 是否显示白色导航
  SET_WHITE_HEADER: (state, status) => {
    state.whiteHeader = status
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
