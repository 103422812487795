import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  lang: `/home/language`, // 语言
  language: `/language/index`, // 语言包
  publicNav: `/home/nav`, // 头部/底部导航
  rightFlex: `/home/sidebar`, // 右侧固定栏
  banner: `/home/banner`, // banner
  navigator: `/home/navigation`, // 面包屑
  follow: `/home/follow` // 社交媒体
}

// 语言
export const getLang = () => fetch(`${apiUrl.lang}/${lang}/${site}`)
// 语言包
export const getLanguage = () => fetch(`${apiUrl.language}/${lang}/${site}`)
// 头部/底部导航
export const getPublicNav = (params) => fetch(`${apiUrl.publicNav}/${lang}/${site}`, params)
// 右侧固定栏
export const getRightFlex = () => fetch(`${apiUrl.rightFlex}/${lang}/${site}`)
// banner
export const getBanner = (params) => fetch(`${apiUrl.banner}/${lang}/${site}` + `?menu_id=${params.menu_id}&banner_id=${params.banner_id}`)
// 面包屑
export const getNavigator = (params) => fetch(`${apiUrl.navigator}/${lang}/${site}` + `?type=${params.type}&id=${params.id}&level=${params.level}`)
// 社交媒体
export const getFollow = () => fetch(`${apiUrl.follow}/${lang}/${site}`)

