import Vue from 'vue'

import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Tabs,
  TabPane,
  Checkbox,
  CheckboxGroup,
  Switch,
  Row,
  Col,
  Pagination,
  Autocomplete,
  Tag,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Radio,
  RadioGroup,
  Message,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Carousel,
  CarouselItem,
  Loading
} from 'element-ui'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

const components = {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Tabs,
  TabPane,
  Checkbox,
  CheckboxGroup,
  Switch,
  Row,
  Col,
  Pagination,
  Autocomplete,
  Tag,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Radio,
  RadioGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Carousel,
  CarouselItem
}
for (const component in components) {
  Vue.use(components[component])
}
Vue.use(Loading.directive)
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
Vue.component(CollapseTransition.name, CollapseTransition)
